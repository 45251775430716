import React from "react";
import PropTypes from "prop-types";
import Nav from "~components/Nav";

const NavClipped = ({ color }) => (
  <div className="nav-clip w-full h-full absolute top-0 right-0 bottom-0 left-0 z-50 clipped overflow-hidden pointer-events-none">
    <Nav clipped color={color} />
  </div>
);

NavClipped.defaultProps = {
  color: `white`
};

NavClipped.propTypes = {
  color: PropTypes.string
};

export default NavClipped;
