/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import Video from "~components/Video.jsx";

const ProductionPage = ({ data, location }) => {
  // ===========================================================================
  // context / ref / state

  const { setLanguageWidgetColor, setNavColor } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const [expanded, setExpanded] = useState(false);

  // ===========================================================================
  // variables

  const { frontmatter } = data.markdownRemark;

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    setLanguageWidgetColor(`black`);
    setNavColor(`black`);
  }, []);

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="production-page w-full relative" location={location}>
        <section className="w-full relative bg-off-white">
          <div className="relative grid pt-16 pb-32 xs:pb-6">
            <h1 className="animation-appear-right animation-delay-3 grid-end-19 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:mt-2 f1">
              {frontmatter.heading}
            </h1>

            <div
              className="animation-appear-right animation-delay-4 grid-end-8 grid-start-17 sm:grid-end-22 sm:grid-start-2 relative block mt-12 pb-12"
              style={{ height: `${expanded ? `auto` : `200px`}` }}
            >
              <div className="w-full h-full relative overflow-hidden">
                <p
                  className="b2 whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: frontmatter.intro }}
                ></p>
              </div>

              <div className="w-full h-12 absolute right-0 bottom-0 left-0 z-10 flex items-end -mb-4">
                <button
                  type="button"
                  className="w-full h-full relative block cursor-pointer text-left"
                  onClick={() => setExpanded(!expanded)}
                >
                  <p className="b2 underline">
                    Read {expanded ? `Less -` : `More +`}
                  </p>
                </button>
              </div>
            </div>

            {frontmatter?.videoUrl && (
              <Video
                className="animation-appear-right animation-delay-5 grid-end-20 grid-start-5 sm:grid-end-22 sm:grid-start-2 mt-16 xs:mt-12 block"
                src={frontmatter.videoUrl}
              />
            )}
          </div>
        </section>

        {frontmatter?.gallery?.[0] && (
          <section className="w-full relative bg-off-white">
            {frontmatter.gallery.map((galleryItem, galleryItemIndex) => {
              const key = `gallery-item-${galleryItemIndex}`;

              if (!galleryItem?.image?.childImageSharp?.fluid) {
                return <React.Fragment key={key} />;
              }

              return (
                <figure key={key} className="w-full relative block">
                  <Img
                    className="w-full relative block"
                    fluid={galleryItem.image.childImageSharp.fluid}
                    alt={`Gallery item ${galleryItemIndex}`}
                  />
                </figure>
              );
            })}
          </section>
        )}

        <GetInTouch withNav />

        <Footer />
      </Layout>
    </>
  );
};

export default ProductionPage;

export const query = graphql`
  query ProductionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        videoUrl

        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        seoDescription
        seoKeywords
      }
    }
  }
`;
