/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import NavClipped from "~components/NavClipped";
import SectionHeading from "~components/SectionHeading";

class GetInTouchComponent extends Component {
  state = {};

  render() {
    const { frontmatter, withNav } = this.props;

    return (
      <>
        {frontmatter && (
          <div className="get-in-touch w-full relative xs:pt-6 pb-32 xs:pb-8 bg-black text-white">
            {withNav && <NavClipped color="white" />}

            <SectionHeading
              color="white"
              text={frontmatter.getInTouchSubheading}
            />

            <article className="relative grid">
              <div className="grid-end-13 grid-start-4 sm:grid-end-22 sm:grid-start-2">
                <h2 className="animation-appear-right animation-delay-1 mt-8 xs:mt-8 f1">
                  {frontmatter.getInTouchHeading}
                </h2>

                <h3 className="animation-appear-right animation-delay-2 w-3/4 mt-16 xs:mt-12 b2">
                  {frontmatter.getInTouchContent}
                </h3>
              </div>

              <ul className="grid-end-4 grid-start-20 sm:grid-end-22 sm:grid-start-2 relative z-10 mt-10 xs:mt-16">
                <li
                  key={frontmatter.officeTitleMelbourne}
                  className="animation-appear-right animation-delay-1 mb-10 xs:mb-16"
                >
                  <h3 className="mb-2 xs:mb-4 f4">
                    {frontmatter.officeTitleMelbourne}
                  </h3>

                  <p className="get-in-touch__address b2">
                    {frontmatter.officeLocationMelbourne}
                  </p>

                  <p className="get-in-touch__address my-1 xs:my-4 b2">
                    {frontmatter.officePhoneMelbourne}
                  </p>

                  <p className="get-in-touch__address b2 break-all">
                    <a
                      href={`mailto:${frontmatter.officeEmailMelbourne}?Subject=Enquiry`}
                      className="underline block"
                    >
                      Email us
                    </a>
                  </p>
                </li>

                <li
                  key={frontmatter.officeTitleSydney}
                  className="animation-appear-right animation-delay-1 mb-10 xs:mb-16"
                >
                  <h3 className="mb-2 xs:mb-4 f4">
                    {frontmatter.officeTitleSydney}
                  </h3>

                  <p className="get-in-touch__address b2">
                    {frontmatter.officeLocationSydney}
                  </p>

                  <p className="get-in-touch__address my-1 xs:my-4 b2">
                    {frontmatter.officePhoneSydney}
                  </p>

                  <p className="get-in-touch__address b2 break-all">
                    <a
                      href={`mailto:${frontmatter.officeEmailSydney}?Subject=Enquiry`}
                      className="underline block"
                    >
                      Email us
                    </a>
                  </p>
                </li>

                <li
                  key={frontmatter.officeTitleShanghai}
                  className="animation-appear-right animation-delay-1 mb-10 xs:mb-16"
                >
                  <h3 className="mb-2 xs:mb-4 f4">
                    {frontmatter.officeTitleShanghai}
                  </h3>

                  <p className="get-in-touch__address b2">
                    {frontmatter.officeLocationShanghai}
                  </p>

                  <p className="get-in-touch__address my-1 xs:my-4 b2">
                    {frontmatter.officePhoneShanghai}
                  </p>

                  <p className="get-in-touch__address b2 break-all">
                    <a
                      href={`mailto:${frontmatter.officeEmailShanghai}?Subject=Enquiry`}
                      className="underline block"
                    >
                      Email us
                    </a>
                  </p>
                </li>
              </ul>
            </article>
          </div>
        )}
      </>
    );
  }
}

const query = graphql`
  query GetInTouch {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            language
            officeTitleMelbourne
            officeDescriptionMelbourne
            officeLocationMelbourne
            officePhoneMelbourne
            officeEmailMelbourne
            officeTitleSydney
            officeDescriptionSydney
            officeLocationSydney
            officePhoneSydney
            officeEmailSydney
            officeTitleShanghai
            officeDescriptionShanghai
            officeLocationShanghai
            officePhoneShanghai
            officeEmailShanghai
            getInTouchSubheading
            getInTouchHeading
            getInTouchContent
          }
        }
      }
    }
  }
`;

const GetInTouch = ({ withNav }) => {
  const appContext = useContext(AppContext);

  return (
    <StaticQuery
      query={query}
      render={({ allMarkdownRemark }) => {
        let localisedFrontmatter;

        allMarkdownRemark.edges.forEach(({ node }) => {
          const { language } = node.frontmatter;

          if (appContext.locale.locale === language) {
            localisedFrontmatter = node.frontmatter;
          }
        });

        return (
          <GetInTouchComponent
            frontmatter={localisedFrontmatter}
            withNav={withNav}
          />
        );
      }}
    />
  );
};

export default GetInTouch;
